import React from 'react';
import './style.css';
import FadeIn from 'react-fade-in';

const Video = () => {
   return (
      <FadeIn>
         <div style={{ marginTop: 80, width: "50%", margin: "auto", textAlign: "center" }}>
            <div style={{display: "inline-block"}}>
               <iframe src="https://roulette.org/event/steve-cohn-2/" title="Steve Cohn Quartet at Roulette"></iframe>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/tUAKkDZHv7s" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div style={{display: "inline-block"}}>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/-e-5A-Evu4k" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/W7AqXnHjxd0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>

            <div style={{display: "inline-block"}}>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/3X7k2D_Zsvo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               <iframe width="560" height="315" src="https://www.youtube.com/embed/KueL2N_CsAo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
         </div>
      </FadeIn>
   );
}

export default Video;