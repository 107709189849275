import React from 'react';
import pnoandbass from '../assets/pnoandbass.png';
import FadeIn from 'react-fade-in';

const Contact = () => {
    return (
        <FadeIn>
            <div className="main">
                <img src={pnoandbass} style={{ width: "100%" }} alt="Steve Cohn playing piano with a bass player" />
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                    email: louyk [at] hotmail [dot] com <br />
                        facebook: Steven Louis Cohn<br />
                        stevecohn.bandcamp.com<br />
                </div>
            </div>
        </FadeIn >
    );
}

export default Contact;