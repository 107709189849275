import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
 
let deviceWidth = window.innerWidth;

const Navigation = () => {
    return (
      deviceWidth > 500
      ?
      <div className="navStyles" style={{height: 70}}>
      <NavLink className="navLinkStyles" to="/">HOME</NavLink>
      <NavLink className="navLinkStyles" to="/discography">DISCOGRAPHY</NavLink>
      <NavLink className="navLinkStyles" to="/video">VIDEO</NavLink>
      <NavLink className="navLinkStyles" to="/press">PRESS</NavLink>
      <NavLink className="navLinkStyles" to="/contact">CONTACT</NavLink>
   </div>
   :
   <div className="navStyles" style={{height: 100, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
   <div style={{marginBottom: 10}}>
   <NavLink className="navLinkStyles" to="/">HOME</NavLink>
      <NavLink className="navLinkStyles" to="/discography">DISCOGRAPHY</NavLink>
   </div>
   <div style={{marginBottom: 10}}>
   <NavLink className="navLinkStyles" to="/video">VIDEO</NavLink>
   <NavLink className="navLinkStyles" to="/press">PRESS</NavLink>
   <NavLink className="navLinkStyles" to="/contact">CONTACT</NavLink>
   </div>
</div>
    );
}
 
export default Navigation;