import React from 'react';
import './style.css';

const Footer = () => {
    return (
        <div className="footer">
            {/* © 2020 Steve Cohn & Modern Improvisation. All rights reserved. All music and art by Steve Cohn. */}
        </div>
    );
}

export default Footer;