import React from 'react';
import './style.css';
import FadeIn from 'react-fade-in';
import bandcamp_logo from '../assets/bandcamp_logo.png';

let deviceWidth = window.innerWidth;

const DiscographyList = [
  ["Arts and Crafts", 1984, "Trio w/ Jay Elfenbein & Chuck Fertal", "White Cow", "https://stevecohn.bandcamp.com/album/arts-crafts"],
  ["Shapes, Sounds, Theories", 1984, "Trio w/ Reggie Workman & Chuck Fertal", "Cadence"],
  ["Clay Bird Square", 1984, "Trio w/ Reggie Workman & Chuck Fertal", "Modern Improvisation", "https://stevecohn.bandcamp.com/album/clay-bird-square"],
  ["The Beggar and the Robot In Diamonds", 1993, "Quartet w/ Fred Hopkins, Jason Kao Hwang & Tom Varner; sextet w/ Frank Lowe, William Parker, Bob Stewart, Karl Berger & Zen Matsuura", "ITM Pacific", "https://stevecohn.bandcamp.com/album/the-beggar-and-the-robot-in-diamonds"],
  ["Ittekimasu (Live at Greenwich Village Jazz Festival)", 1987, "Trio w/ Fred Hopkins & Thurman Barker", "White Cow & ITM Pacific", "https://stevecohn.bandcamp.com/album/ittekimasu"],
  ["Sufi Dancers", 1982, "Quintet w/ Warren Gale, David Schrader, John Donnelly & Larry Hancock", "White Cow", "https://stevecohn.bandcamp.com/album/sufi-dancers-bonus-tracks-edition"],
  ["Bridge Over The X-Stream (Live at the Knitting Factory)", 1999, "Quartet w/ Reggie Workman, Jason Kao Hwang & Tom Varner", "Leo", "https://stevecohn.bandcamp.com/album/bridge-over-the-x-stream"],
  ["Live In New York", 1997, "Trio w/ William Parker & Denis Charles", "Tube Room", "https://carlbaugher.bandcamp.com/album/live-in-new-york"],
  ["Anspruchsvoll", 2012, "Solo piano", "Unseen Rain", "https://www.youtube.com/playlist?list=OLAK5uy_lFcIr4M2Hcs-NLdLwFArkpmNu4VsNRc-E"],
  ["Manhattan 2 Moonshine", 2012, "Solo piano", "Mega International"],
  ["Faces", 2013, "Duo w/ Perry Robinson", "Tube Room", "https://carlbaugher.bandcamp.com/album/faces"],
  ["Dream Time", 2018, "New York Free Quartet w/ Michael Moss, Larry Roland & Chuck Fertal", "4th Stream", "https://michaelmoss.bandcamp.com/album/dream-time"],
  ["In Between Gigs... Can You Dig?", 2017, "New York Free Quartet w/ Michael Moss, Larry Roland & Chuck Fertal", "4th Stream", "https://michaelmoss.bandcamp.com/album/in-between-gigs-can-you-dig"],
  ["Free Play", 2015, "New York Free Quartet w/ Michael Moss, Larry Roland & Chuck Fertal", "4th Stream", "https://michaelmoss.bandcamp.com/album/free-play"],
  ["Wind Shape", 2020, "Quartet w/ James Cook, Mike Harmon & Chuck Fertal", "Tube Room", "https://carlbaugher.bandcamp.com/album/wind-shape"],
  ["The Novel", 2010, "Quartet w/ Guillermo Gregorio, Masahiko Kono & Bruce Ditmas", "Modern Improvisation", "https://stevecohn.bandcamp.com/album/the-novel"],
  ["Round the World", 2013, "Solo piano", "Unseen Rain"],
  ["We", 1991, "Trio w/ William Parker & Tom Tedesco", "Modern Improvisation", "https://stevecohn.bandcamp.com/album/we"],
  ["Iro Iro (Live at Knitting Factory)", 1991, "Quartet w/ Masahiko Kono, Tomas Ulrich & Kevin Norton", "Red Toucan", "https://stevecohn.bandcamp.com/album/iro-iro"],
  ["Wire", 2007, "Quintet w/ Adam Lane, Ray Sage, Blaise Siwula & Motoko Shimizu", "Konnex"],
  ["Voyager", 2018, "Quartet w/ Daniel Carter, Larry Roland & Marvin Bugalu Smith", "Tube Room", "https://carlbaugher.bandcamp.com/album/voyager"],
  ["Quarter to Three", 2017, "Trio w/ Annabelle Plum & Marek Kimel Matvija", "Poli5", "https://quartertothree.bandcamp.com/album/quarter-to-three"],
  ["Converzations with Jules", 2021, "Trio w/ Bob Stewart & Barry Altschul", "Modern Improvisation", "https://stevecohn.bandcamp.com/album/converzations-with-jules"],
  ["Futurity", 2018, "Trio w/ John Marglin & Carl Baugher", "Tube Room", "https://carlbaugher.bandcamp.com/album/futurity"],
  ["Helix", 2017, "Michael Moss Accidental Orchestra", "4th Stream", "https://michaelmoss.bandcamp.com/album/helix"],
  ["Me Being Me", 2018, "Solo piano", "Mega International"],
  ["Seven Steps To Infinity", 2006, "w/ Anthony Mirabile & Doc Holiday", "Mega International"],
  ["Albany Park", 2018, "Duo w/ Jimmy Bennington", "Slam Records"],
  ["Kings In The Mountain", 2022, "Trio w/ Thomas Milovac & Ben Shorstein", "Cosmosonic Collective"],
  ["Echo", 2020, "Duo w/ Carl Baugher", "Tube Room", "https://carlbaugher.bandcamp.com/album/echo"],
  ["Cowboy Film School", 2008, "Quartet w/ Tony Decicco, Bruce Ditmas & Chris Kelsey", "Unseen Rain"],
  ["Spokenash", 2019, "Quintet w/ Anthony Mirabile, Dale Herr, David Roe & Craig Krampt", "Mega International"],
  ["The Blair Recordings", 2002, "w/ Blair Hardman & Johnny De Robertis", "Leo"],
  ["Ancient & Modern", 2019, "Trio w/ George Haslam & Steve Kershaw", "Slam Records"],
  ["Music", 2019, "Duo w/ David Pate", "Self-published", "https://davidpate.bandcamp.com/album/music"],
  ["Falling Outside The Normal Moral Constaints", 2022, "Trio w/ Thomas Milovac & Ben Shorstein", "Cosmosonic Collective", "https://cosmosoniccollective.bandcamp.com/album/falling-outside-the-normal-moral-constraints"],
  ["No Lunch in Hackensack", 2013, "Duo w/ Jimmy Bennington", "That Swan"],
  ["New Jersey Freebie", 2019, "Trio w/ Ed Schuller & Jimmy Bennington", "Slam Records"],
  ["Moody O Kyo", 2018, "Trio w/ Kyo Inaba & Santi Debriano", "Kojak"],
  ["At Home", 2021, "Solo piano", "Tube Room", "https://carlbaugher.bandcamp.com/album/at-home"],
  ["Iro Iro Volume 2 (Live at Knitting Factory)", 1998, "Quartet w/ Kevin Norton, Masahiko Kono & Tomas Ulrich", "Modern Improvisation", "https://stevecohn.bandcamp.com/album/iro-iro-band-volume-2"],
  ["Arts and Crafts: The Alternate Takes", 1984, "Trio w/ Jay Elfenbein & Chuck Fertal", "White Cow", "https://stevecohn.bandcamp.com/album/arts-crafts-the-alternate-takes"],
]

const n = 200;
const k = 150;
const m = 13;

const Discography = () => {
  return (
    <FadeIn className="main">
      {deviceWidth > 500 ? <div style={{ justifyContent: 'center', backgroundColor: 'black' }}>
        <div style={{ backgroundColor: "black" }}>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=1197871241/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/sufi-dancers-bonus-tracks-edition">Sufi Dancers (Bonus Tracks Edition) by Steve Cohn</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=1449027960/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/ittekimasu">Ittekimasu by Steve Cohn</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=3525627093/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/arts-crafts">Arts &amp; Crafts by Steve Cohn</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=356211210/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/the-beggar-and-the-robot-in-diamonds">The Beggar and the Robot in Diamonds by Steve Cohn</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=3710683714/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/bridge-over-the-x-stream">Bridge Over the X-Stream by Steve Cohn</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=1369519868/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://carlbaugher.bandcamp.com/album/voyager">Voyager by Steve Cohn, Larry Roland, Daniel Carter &amp; Marvin Bugalu Smith</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=374340348/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://carlbaugher.bandcamp.com/album/wind-shape">Wind Shape by The Steve Cohn Quartet</a></iframe>
          <iframe style={{ height: n, width: n }} src="https://bandcamp.com/EmbeddedPlayer/album=3112391955/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/clay-bird-square">Clay Bird Square by Steve Cohn</a></iframe>
        </div>
        <p style={{ fontSize: 30, marginTop: 30, textAlign: "center" }}><b><i>SELECTED DISCOGRAPHY</i></b></p>
        <div style={{ textAlign: "left" }}>
          <table>
            {DiscographyList.map((album, i) =>
              <tr style={{ padding: 5 }}>
                <th>{album[0]}</th>
                <td style={{ paddingLeft: 15 }}>{album[1]}</td>
                <td style={{ paddingLeft: 15 }}>{album[2]}</td>
                <td style={{ paddingLeft: 15 }}>{album[3]}</td>
                {album[4] ? <td> <a href={album[4]}><img src={bandcamp_logo} alt="bandcamp logo" style={{ width: 25 }}></img></a></td> : <td></td>}
              </tr>)}
          </table>
        </div>
      </div>

        :

        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: 'black'}}>
<div style={{backgroundColor: 'black', display: 'flex', flexDirection: 'row', marginLeft: '8%'}}>
<div style={{ display: 'flex', flexDirection: 'column' }}>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=1197871241/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/sufi-dancers-bonus-tracks-edition">Sufi Dancers (Bonus Tracks Edition) by Steve Cohn</a></iframe>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=1449027960/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/ittekimasu">Ittekimasu by Steve Cohn</a></iframe>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=356211210/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/the-beggar-and-the-robot-in-diamonds">The Beggar and the Robot in Diamonds by Steve Cohn</a></iframe>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=3710683714/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/bridge-over-the-x-stream">Bridge Over the X-Stream by Steve Cohn</a></iframe>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=1369519868/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://carlbaugher.bandcamp.com/album/voyager">Voyager by Steve Cohn, Larry Roland, Daniel Carter &amp; Marvin Bugalu Smith</a></iframe>
                <iframe style={{ height: k, width: k }} src="https://bandcamp.com/EmbeddedPlayer/album=3112391955/size=large/bgcol=ffffff/linkcol=0687f5/minimal=true/transparent=true/" seamless><a href="https://stevecohn.bandcamp.com/album/clay-bird-square">Clay Bird Square by Steve Cohn</a></iframe>
              </div>
</div>
          <p style={{ fontSize: 15, marginTop: 30, marginLeft: '20%'}}><b><i>SELECTED DISCOGRAPHY</i></b></p>
          <table>
              {DiscographyList.map((album, i) =>
                <tr style={{ padding: 5 }}>
                  <th style={{ fontSize: m, width: '20%'}}>{album[0]}</th>
                  <td style={{ fontSize: m, width: '5%'}}>{album[1]}</td>
                  <td style={{ fontSize: m, width: '20%'}}>{album[2]}</td>
                  <td style={{ fontSize: m, width: '20%'}}>{album[3]}</td>
                  {album[4] ? <td style={{ fontSize: m, width: '20%'}}> 
                  <a href={album[4]}><img src={bandcamp_logo} alt="bandcamp logo" style={{ width: m }}>
                    </img></a></td> : <td style={{ fontSize: m, width: '5%'}}></td>}
                </tr>)}
            </table>
        </div>}
    </FadeIn>
  );
}

export default Discography;
